<template>
    <div>
        <page-header pageTitle=""
        ></page-header>
        <h4>Resumo de clientes</h4>

        <div class="d-flex flex-row" style="gap: 8px; height: 360px">
            <div class="card p-2 flex-grow-1" style="flex-basis: 0;" :style="loadingGrafic('resumS')">
                <div class="spinner-border align-self-center" role="status" v-if="loadingChartSex">
                    <span class="sr-only"></span>
                </div>
                <div id="clientSex"></div>
            </div>
            <div class="card p-2 flex-grow-1" style="flex-basis: 0;" :style="loadingGrafic('resumA')">
                <div class="spinner-border align-self-center" role="status" v-if="loadingChartAge">
                    <span class="sr-only"></span>
                </div>
                <div id="clientAge"></div>
            </div>
            <div class="card p-2 flex-grow-1" style="flex-basis: 0;" :style="loadingGrafic('resumM')">
                <div class="spinner-border align-self-center" role="status" v-if="loadingChartMaritalStatus">
                    <span class="sr-only"></span>
                </div>
                <div id="clientMaritalStatus"></div>
            </div>
        </div>
        <h4>{{t('GENERAL.MSG.PROGRESS_REGISTER_CLIENTS')}}</h4>
        <div>
        <div class="card p-2" style="height: 74vh" :style="loadingGrafic('progress')">
            <div class="spinner-border align-self-center" role="status" v-if="loadingChartProgress">
                <span class="sr-only"></span>
            </div>
            <div id="apex" style="height: 50vh"></div>
        </div>
        </div>
    </div>
</template>

<script>
import pageHeader from "../../components/layouts/pageHeader";
import Clients from "../../services/Clients";
import * as ApexCharts from "apexcharts";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";

export default {
    name: "dashboardClient",
    components: {
        pageHeader,
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    beforeRouteLeave(to, from, next) {
        this.chartSex?.destroy();
        this.chartAge?.destroy();
        this.chartMaritalStatus?.destroy();
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
        next();
    },
    data() {
        return {
            items: null,
            filters: null,
            chart: null,
            chartSex: null,
            chartAge: null,
            chartMaritalStatus: null,
            loadingChartProgress: null,
            loadingChartSex: null,
            loadingChartAge: null,
            loadingChartMaritalStatus: null,
        }
    },
    unmounted() {
        this.chartSex?.destroy();
        this.chartAge?.destroy();
        this.chartMaritalStatus?.destroy();
    },
    mounted() {
        this.renderChart();
        this.graficResumClientSex();
        this.graficResumClientAge();
        this.graficResumClientMaritalStatus();
    },
    methods: {
        async index() {
            await Clients.dashboard().then(resp => {
                this.dateRegisters = resp.data.dateRegisters;
                this.seriesData = resp.data.seriesData;
            }).catch(error => {
                console.error("Failed to fetch data:", error);
                this.toast.error("Failed to fetch data.");
            });
        },
        async renderChart() {
            this.loadingChartProgress = true;
            await this.index();

            if (!this.seriesData || this.seriesData.length === 0) {
                this.loadingChartProgress = false;
                return;
            }

            const formattedDates = this.dateRegisters;
            const options = {
                series: this.seriesData,
                chart: {
                    type: 'bar',
                    height: '85%',
                    stacked: true,
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: true | '<img src="/static/icons/reset.png" width="20">',
                            customIcons: []
                        },
                        autoSelected: 'zoom'
                    },
                    zoom: {
                        enabled: true
                    },
                    selection: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        endingShape: "rounded",
                        borderRadius: 0,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    }
                },
                xaxis: {
                    type: 'category',
                    tickPlacement: 'on',
                    categories: formattedDates,
                    labels: {
                        show: true,
                        formatter: (date) => {
                            if (!date) {
                                return '-'
                            }
                            date = date.split('-')
                            return `${date[2]}/${date[1]}/${date[0]}`;
                        },
                    }
                },
                legend: {
                    position: 'bottom',
                    offsetY: 10,
                    showForSingleSeries: true,
                },
                fill: {
                    opacity: 1
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }]
            };

            const el = document.getElementById('apex');
            if (el) {
                this.chart = new ApexCharts(el, options);
                this.chart.render();
            } else {
                console.error("Element with id 'apex' not found");
            }

            this.loadingChartProgress = false;
        },
        graficResumClientAge() {
            this.loadingChartAge = true;
            this.chartAge?.destroy();
            Clients.getResumClientAge().then(resp => {
                let series = [];
                let labels = [];
                resp.data.forEach(i => {
                    series.push(i.total);
                    labels.push(`${i.age_range} (${i.generation})`);
                })
                var options = {
                    series: series,
                    chart: {
                        type: 'donut',
                        height: '100%',
                    },
                    title: {
                        text: 'Resumo por idade',
                        align: 'center',
                    },
                    legend:{
                        position: 'bottom',
                    },
                    labels: labels,
                };

                this.chartAge = new ApexCharts(document.querySelector("#clientAge"), options);
                this.chartAge.render();
                this.loadingChartAge = false;
            })
        },
        graficResumClientMaritalStatus() {
            this.loadingChartMaritalStatus = true;
            this.chartMaritalStatus?.destroy();
            Clients.getResumClientMaritalStatus().then(resp => {
                let series = [];
                let labels = [];
                Object.values(resp.data)?.forEach(i => {
                    console.log(i)
                    series.push(i.total);
                    labels.push(i.marital_status);
                })
                console.log(series);
                console.log(labels);
                var options = {
                    series: series,
                    chart: {
                        type: 'donut',
                        height: '100%',
                    },
                    title: {
                        text: 'Resumo por estado civil',
                        align: 'center',
                    },
                    legend:{
                        position: 'bottom',
                    },
                    labels: labels,
                };

                this.chartMaritalStatus = new ApexCharts(document.querySelector("#clientMaritalStatus"), options);
                this.chartMaritalStatus.render();
                this.loadingChartMaritalStatus = false;
            })
        },
        graficResumClientSex() {
            this.loadingChartSex = true;
            this.chartSex?.destroy();
            Clients.getResumClientSex().then(resp => {
                let series = [];
                let labels = [];
                resp.data.forEach(i => {
                    series.push(i.total);
                    labels.push(i.sex);
                })
                var options = {
                    series: series,
                    chart: {
                        type: 'donut',
                        height: '100%',
                    },
                    title: {
                        text: 'Resumo por gênero',
                        align: 'center',
                    },
                    legend:{
                        position: 'bottom',
                    },
                    labels: labels,
                };

                this.chartSex = new ApexCharts(document.querySelector("#clientSex"), options);
                this.chartSex.render();
                this.loadingChartSex = false;
            })
        },
        loadingGrafic(context) {
            if (context === 'progress' && this.loadingChartProgress) return 'display: flex; justify-content: center;';
            if (context === 'resumS' && this.loadingChartSex) return 'display: flex; justify-content: center;';
            if (context === 'resumA' && this.loadingChartAge) return 'display: flex; justify-content: center;';
            if (context === 'resumM' && this.loadingChartMaritalStatus) return 'display: flex; justify-content: center;';
            return '';
        },
    }

}
</script>

<style scoped>
.apexcharts-legend {
    justify-content: start !important;
}
</style>
